import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import Img from "gatsby-image"
import SEO from "../../components/seo"

const HandsOnEducation = () => {
  const data = useStaticQuery(graphql`
    query {
      composite: file(relativePath: { eq: "visit-us/hands-on-education/composite.png" }) {
        childImageSharp {
          fixed {
            ...GatsbyImageSharpFixed
          }
        }
      },
    }
  `)
  return (
    <Layout>
      <SEO title="Hands On Education" />
      <br />
      <h1>Hands On Education</h1>
      <div className="row">
        <div className="col-sm-9">
          <h3 id="make-a-splash-as-a-blue-team" style={{position: `relative`}}>
            <a href="#make-a-splash-as-a-blue-team" aria-label="make a splash as a blue team permalink" class="anchor before">
              <svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fill-rule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg>
            </a>
            Make a Splash as a Blue Team!
          </h3>
          <p>
            <strong>We are looking for student or community groups to restore the wetlands and forests at Baxter Barn!</strong>
          </p>
          <p>Because of a generous grant from the King Conservation District, we are creating Kid-Powered Blue Teams to help restore water quality to the stream system that runs through some of Fall City’s most beautiful small farm country, near the Baxter Barn. Led by a Nature Vision naturalist, Blue Teams will carry out a water quality action project that promotes sustainability and stewardship in our community, while learning about wetland and forest ecology. Lessons are connected to WA State EALRS.</p>
          <h4 id="what-is-a-blue-team" style={{position: `relative`}}>
            <a href="#what-is-a-blue-team" aria-label="what is a blue team permalink" class="anchor before">
              <svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fill-rule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg>
            </a>
            What is a Blue Team?
          </h4>
          <p>A <strong>Blue Team</strong> is a group of kids (could be a classroom, school club, or a neighborhood, home school, scout or camp group) who commit to taking on and completing a watershed-action stewardship project in their community. <strong>Blue Teams</strong> will receive 4 hours of expert help from a Nature Vision Water Specialist - help in planning, preparing for, and executing your restoration project. <strong>Blue Teams</strong> also receive recognition upon completion of the project. This program is available to students in grades K – 8! The stewardship project must be completed within the school year; however, groups can re-up year after year (depending on funding).</p>
          <h4 id="about-baxter-barn" style={{position: `relative`}}>
            <a href="#about-baxter-barn" aria-label="about baxter barn permalink" class="anchor before">
              <svg aria-hidden="true" focusable="false" height="16" version="1.1" viewBox="0 0 16 16" width="16"><path fill-rule="evenodd" d="M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"></path></svg>
            </a>
            About Baxter Barn
          </h4>
          <p>Baxter Barn is a small local farm, which is committed to being a leader in educating our farming community about Best Management Practices that help keep our water, air, and soil quality healthy in the Puget Sound watershed. Baxter Barn leads by example by preserving and conserving native plants and wildlife on their private historical farm property since 1875. Cory Huskinson, owner and curator of Baxter Barn, has launched an extensive restoration project with King Conservation District and would like to use his property to educate students and adults in the Snoqualmie Valley community about how to live and work on a farm and practice being a good watershed steward!</p>
          <p>Limited Parking up to 5 cars/vans</p>
        </div>
        <div className="col-sm-3 text-right" style={{margin: `auto`}}>
          <Img alt="Hands on Education pictures from Baxter Barn" fixed={data.composite.childImageSharp.fixed} />
        </div>
      </div>
    </Layout>
  )
}

export default HandsOnEducation
